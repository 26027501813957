import { SVGProps, Ref, forwardRef } from 'react'

const SvgPlus = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 13v8h-2v-8H3v-2h8V3h2v8h8v2h-8Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgPlus)
export default ForwardRef
